import Select, { StylesConfig } from 'react-select';
import { apiClient } from 'helpers/APIClient';
import { toastSettings } from 'helpers/toastSettings';
import React from 'react';
import { toast } from 'react-toastify';
import { DEALabel, DEALabelObject } from 'types';
import { apiBase, colors } from 'variables';
import styled from 'styled-components';

interface Props {
  projectId: number;
  labels: DEALabelObject[];
  refresh: () => Promise<void>;
  category: string;
}

const Wrapper = styled.div`
  flex: 0 0 170px;
  padding-right: 8px;
  font-size: 0.9rem;
  color: ${colors.secondary};
`;

const customStyles: StylesConfig = {
  control: provided => ({
    ...provided,
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    justifyContent: 'flex-end',
    maxWidth: '170px',
  }),
  valueContainer: provided => ({ ...provided, paddingLeft: 0 }),
  placeholder: provided => ({ ...provided }),
  singleValue: provided => ({ ...provided, color: colors.secondary }),
  indicatorSeparator: () => ({ display: 'none' }),
  indicatorsContainer: provided => ({
    ...provided,
    padding: '0 !important',
    '*': {
      padding: '0 !important',
    },
    svg: {
      color: colors.secondary,
    },
  }),
  menu: provided => ({ ...provided }),
  menuList: provided => ({
    ...provided,
    backgroundColor: '#58585A',
    padding: '10px',
  }),
  option: (provided, state) => ({
    ...provided,
    textDecoration: state.isSelected ? 'underline' : 'none',
    color: state.isSelected ? 'white' : colors.secondary,
    backgroundColor: 'transparent',

    ':hover': {
      backgroundColor: colors.background,
    },
  }),
  multiValueLabel: provided => ({
    ...provided,
    backgroundColor: colors.background,
    color: 'white',
  }),
  multiValue: provided => ({
    ...provided,
    backgroundColor: colors.background,
    color: colors.error,
  }),
};

const options = [
  {
    label: 'Winner of the Year',
    value: DEALabel.WinnerOfTheYear,
  },
  {
    label: 'Gold Prize',
    value: DEALabel.GoldPrize,
  },
  {
    label: 'Silver Prize',
    value: DEALabel.SilverPrize,
  },
  {
    label: 'Bronze Prize',
    value: DEALabel.BronzePrize,
  },
  {
    label: 'Special Recognition',
    value: DEALabel.SpecialRecognition,
  },
  {
    label: 'Honorable Mention',
    value: DEALabel.HonorableMention,
  },
  {
    label: 'Emerging Designer',
    value: DEALabel.EmergingDesigner,
  },
  {
    label: 'Solarux Choice',
    value: DEALabel.SolaruxChoice,
  },
  { label: 'Selected', value: DEALabel.Selection },
  { label: 'Group 1', value: 'GROUP_1' },
  { label: 'Group 2', value: 'GROUP_2' },
  { label: 'Group 3', value: 'GROUP_3' },
];

export const DEAwardCell: React.FC<Props> = ({ projectId, labels, refresh, category }) => {
  const [isFetching, setIsFetching] = React.useState(false);

  const onChange = async (label: string, isRemoving: boolean) => {
    setIsFetching(true);

    try {
      isRemoving
        ? await apiClient.delete(`${apiBase}admin_panel/projects/${projectId}/dea_label/`, {
            data: {
              deaLabel: label,
              dea_label_category: category,
            },
          })
        : await apiClient.put(`${apiBase}admin_panel/projects/${projectId}/dea_label/`, {
            deaLabel: label,
            dea_label_category: category,
          });
      await refresh();
    } catch {
      toast('DEA score failed', toastSettings);
    } finally {
      setIsFetching(false);
    }
  };

  return (
    <Wrapper>
      {isFetching ? (
        <span>
          <i className="fa fa-circle-o-notch fa-spin" />
        </span>
      ) : (
        <Select
          options={options}
          styles={customStyles}
          isMulti
          value={options.filter(o =>
            labels.some(label => label.deaLabel === o.value && category === label.deaLabelCategory),
          )}
          isSearchable={false}
          isClearable={false}
          onChange={(_, change) => {
            if (change.removedValue) {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              onChange(change.removedValue.value, true);
            } else if (change.option) {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              onChange(change.option.value, false);
            }
          }}
        />
      )}
    </Wrapper>
  );
};
